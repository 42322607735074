import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts"

import "../styles/article.scss"
import 'uikit/dist/css/uikit.css'
//import UIkit from 'uikit'

import GetImgUrl from "../utils/customFunctions"

import useSiteMetadata from "../utils/useSiteMetadata"
import CustomMarkdown from "../utils/customMarkdown"

import Paragraph from "../components/paragraph/paragraph";
import CTABanner from "../components/cta-banner/cta-banner";
import Header from "../components/header/header"
import LatestNews from "../components/latest-news/latest-news";
import Banner from "../components/banner/banner"
import CTAList from "../components/cta-list/ctaList"
import HomeCards from "../components/home-cards/home-cards";
import Comparison from "../components/comparison/comparison";
import ImageCarousel from "../components/image-carousel/image-carousel";


// const ImageCarousel = loadable(() => import("../components/image-carousel/image-carousel"))
// const Header = loadable(() => import("../components/header/header"))
// const Banner = loadable(() => import("../components/banner/banner"))
// const Reviews = loadable(() => import("../components/reviews/reviews"))
// const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
// const CTAList = loadable(() => import("../components/cta-list/ctaList"))
// const CTABanner = loadable(() => import("../components/cta-banner/cta-banner"))
// const Comparison = loadable(() => import("../components/comparison/comparison"))
// const HomeCards = loadable(() => import("../components/home-cards/home-cards"))


export const query = graphql`
  
    query articlePageQuery($id: Int) {
      strapiArticles(strapiId: { eq: $id }) {
        PageContent
        Author
        Description
        SEOTitle
        SEODescription
        Slug
        Title
            
        }
      }
`;




// markup
const Article = ({ pageContext, data }) => {

  function getImgUrl(localFile___NODE) {

    var imgUrl = { imgName: null, basicUrl: null, fluid: null, gatsbyImageData: null }

    data.allFile.edges.map((item, index) => {

      if (item.node.id === localFile___NODE) {
        // if(item.node.publicURL.endsWith(".svg")){
        //   imgUrl.basicUrl = item.node.publicURL
        // } else {
        //   imgUrl.fluid = item.node.childImageSharp.fluid
        //   imgUrl.basicUrl = item.node.publicURL
        // }
        imgUrl.imgName = item.node.name ? item.node.name : null
        imgUrl.basicUrl = item.node.publicURL ? item.node.publicURL : null
        imgUrl.fluid = item.node.childImageSharp ? item.node.childImageSharp.fluid : null
        imgUrl.gatsbyImageData = item.node.childImageSharp ? item.node.childImageSharp.gatsbyImageData : null

        // imgUrl = item.node.publicURL
      }
      return ("")

    })
    //   console.log(imgUrl)
    return (imgUrl)
  }



  const { siteUrl: url } = useSiteMetadata()



  return (//returns the related layout
    <Layout
      SEOTitle={data.strapiArticles.SEOTitle || data.strapiArticles.Title}
      SEODescription={data.strapiArticles.SEODescription} footerDisclaimer={data.strapiArticles.FooterDisclaimer}
      darkThemeNav={true}>

      <div className="article-page">
        <div className="article-container">
          <div className="article-content">
            <h2>{data.strapiArticles.Title}</h2>
            <CustomMarkdown children={data.strapiArticles.Description}></CustomMarkdown>
          </div>

        </div>
        {
          data.strapiArticles.PageContent?.map((item, index) => {


            switch (item.strapi_component) {
              case "page-components.header":
                
                //console.log(item.Background)

                return (
                  <>
                    <Header key={index}
                      title={item.Title}
                      markdownTitle={item.MarkdownTitle}
                      subtitle={item.Subtitle}
                      secondarySubtitle={item.SecondSubtitle}
                      CTAText={item.CTALabel}
                      CTAUrl={item.CTAUrl}
                      CTA_nofollow={item.CTA_nofollow}
                      Disclaimer={item.Disclaimer}
                      Background={item.Background !== null ? GetImgUrl(item.Background.localFile___NODE).gatsbyImageData : ""}
                      HeaderHeight={item.HeaderHeight}
                    ></Header>
                  </>

                )

              case "page-components.image-slider":
                var contentsList = []

                item.Picture.map((image, index) => {

                  var imageUrl = GetImgUrl(image.Image.localFile___NODE).gatsbyImageData
                  contentsList.push({
                    ALT: image.ALT,
                    Image: imageUrl,
                  })
                  return ("")
                })
                return (

                  <ImageCarousel
                    key={index}
                    contents={contentsList}
                  ></ImageCarousel>
                )
              case "page-components.comparison":
                return (

                  <Comparison key={index}
                    title={item.Title}
                    step1={item.Step1}
                    step2={item.Step2}
                    step3={item.Step3}
                    image1={getImgUrl(item.Image1.localFile___NODE)}
                    image2={getImgUrl(item.Image2.localFile___NODE)}
                    image3={getImgUrl(item.Image3.localFile___NODE)}
                  ></Comparison>


                )


              case "page-components.cta-banner":
                return (

                  <CTABanner
                    key={index}
                    Title={item.Title}
                    Subtitle={item.Subtitle}
                    ctaUrl={item.CTAUrl}
                    ctaLabel={item.CTALabel}
                  ></CTABanner>

                )

                case "page-components.latest-news":
                  return (
                    <LatestNews
                        key={index}
                        title={item.Title}
                        subtitle = {item.Subtitle}
                        bgColor={item.BackgroundColor}
                    />
                  )
              case "page-components.banner":
                var imageUrl = GetImgUrl(item.Image.localFile___NODE).gatsbyImageData
                return (
                  <Banner
                    key={index}
                    text={item.Text}
                    image={imageUrl}
                    imageAlt={item.Image.alternativeText}
                    dark={item.DarkBackground}
                    title={item.Title}
                    subtitle={item.Subtitle}
                  ></Banner>

                )

              case "page-components.home-cards":
                var contentsList = []
                item.CTACard.map((CTAitem, index) => {
                  var imageUrl = CTAitem.Image !== null ? getImgUrl(CTAitem.Image.localFile___NODE) : ""
                  contentsList.push({
                    Title: CTAitem.Title,
                    URL: CTAitem.URL,
                    Image: imageUrl,
                    Description: CTAitem.Description,
                  })
                  return ("")
                })
                return (

                  <HomeCards
                    key={index}
                    contents={contentsList}
                  ></HomeCards>


                )

              case "page-components.paragraph":
                return (

                  <Paragraph
                    key={index}
                    title={item.Title ? item.Title : ""}
                    subtitle={item.SubTitle ? item.SubTitle : ""}
                    text={item.Text}
                    bgColor={item.BackgroundColor || ""}

                  ></Paragraph>
                )

              case "page-components.cta-list":
                var contentsList = []
                item.CTAList.map((CTAitem, index) => {
                  var imageUrl = GetImgUrl(CTAitem.Image.localFile___NODE).gatsbyImageData
                  contentsList.push({
                    Title: CTAitem.Title,
                    URL: CTAitem.URL,
                    URLLabel: CTAitem.URLLabel,
                    ImageURL: CTAitem.Image,
                    ImageURL: imageUrl,
                    Alt: CTAitem.Image.alternativeText,
                    Description: CTAitem.Description,
                    DescriptionCharLimit: CTAitem.DescriptionCharLimit
                  })
                  return ("")
                })
                return (

                  <CTAList
                    key={index}
                    title={item.Title ? item.Title : ""}
                    subtitle={item.Subtitle}
                    contents={contentsList}
                    cardStyle={item.CardStyle}
                  ></CTAList>


                )
              default:
                return ("")

            }
          })
        }

      </div>
    </Layout>
  )

}


export default Article