import React from "react"
import "../paragraph/paragraph.scss"
//import 'uikit/dist/css/uikit.css'
import "./latest-news.scss";
import { useStaticQuery, graphql } from "gatsby"

import CustomMarkdown from "../../utils/customMarkdown"

import CTAList from "../cta-list/ctaList";

const LatestNews = ({ title, subtitle, bgColor }) => {

  const data = useStaticQuery(graphql`
    {
        allStrapiArticles(limit: 3, sort: {order: DESC, fields: created_at}) {
            nodes {
               Author
                      Description
                      Title
                      Slug
                      created_at
                      Image{
                        localFile{
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 1900) {
                                  ...GatsbyImageSharpFluid_withWebp
                                }
                                gatsbyImageData(
                                  width: 1900
                                  placeholder: BLURRED
                                  formats: [AUTO, WEBP]
                                )
                              }
                        }
                      }
            }
          }
        allStrapiEvents {
            nodes {
                Image{
                  localFile{
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1900) {
                       ...GatsbyImageSharpFluid_withWebp
                      }
                      gatsbyImageData(
                        width: 1900
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                      )
                    }
                  }
                }
                FromDate(formatString: "MMMM D YYYY")
                Title
                Slug
                Description
                ToDate(formatString: "MMMM D YYYY")
                created_at
              }
          }

      allFile {
        edges {
          node {
            publicURL
            id
            name
            childImageSharp {
              fluid(quality: 90, maxWidth: 1900) {
                ...GatsbyImageSharpFluid_withWebp
              }
              gatsbyImageData(
                width: 1900
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      
    }
    `)

  const backgroundColor = bgColor ? bgColor == "grey" ? "#F5F5F5" : "#FFFFFF" : ""

  const today = new Date();

  //console.log(data.allStrapiArticles.nodes, "test")

  const lastNews = data.allStrapiArticles.nodes;
  //[...data.allStrapiArticles.nodes].sort(function (a, b) { return new Date(b.created_at) - new Date(a.created_at); });

  const lastEvents = data.allStrapiEvents.nodes.filter(item => {
    return new Date(item.ToDate) > today;
  }) ?
    data.allStrapiEvents.nodes
      .filter(item => { return new Date(item.ToDate) > today })
      .sort((a, b) => new Date(a.ToDate) - new Date(b.ToDate))
    :
    [...data.allStrapiEvents.nodes].sort(function (a, b) { return new Date(b.created_at) - new Date(a.created_at); });

  //console.log(lastEvents, "test")

  lastNews.forEach((item, index) => {

    item.FromDate ? item.CardType = "event" : item.CardType = "news"

  })

  lastEvents.forEach((item, index) => {

    item.FromDate ? item.CardType = "event" : item.CardType = "news"

  })

  return (
    <>
      {
        title.includes("vents") && lastEvents.length == 0 ?
          ""
          :
          <div className="latest-news-container" style={backgroundColor ? { backgroundColor: backgroundColor } : ""}>
            <div className="latest-news-content">
              <div className="paragraph-container" >
                <div className="paragraph-content">
                  <div className="paragraph-header">
                    {
                      title ? <h3 className="latest-news-title">{title}</h3> : ""
                    }
                    {
                      subtitle ? <CustomMarkdown children={subtitle}></CustomMarkdown> : ""

                    }
                  </div>
                </div>
              </div>
              <div className="latest-news-cards" style={{ marginTop: '-40px' }}>
                {title === "Latest news" ?
                  <CTAList
                    contents={lastNews.slice(0, 3)}
                  ></CTAList>
                  :
                  lastEvents.length == 0 ?
                    <p className="no-event"></p>
                    :
                    <CTAList
                      contents={lastEvents.slice(0, 3)}
                    ></CTAList>
                }

              </div>

            </div>
          </div>
      }


    </>
  )
}

export default LatestNews